<template>
  <dx-util-popup
    ref="materialPurchasePopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    height="auto"
    width="600px"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="materialPurchasePopupShown"
    @hidden="materialPurchasePopupHidden"
  >
    <div>
      <dx-util-form ref="materialPurchaseFormRef" :form-data="materialPurchaseForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="materialPurchase">
        <dx-util-item ref="titleRef" data-field="title" :label="{text: 'Item Name'}" editor-type="dxTextBox" :editor-options="itemOptions" />
        <dx-util-item data-field="vendorCompanyId" editor-type="dxSelectBox" :label="{text: 'Vendor Name'}" :editor-options="vendorOptions">
          <dx-util-required-rule message="Vendor is required" />
        </dx-util-item>
        <dx-util-item template="vendorAdder" />
        <dx-util-group-item :col-count="3">
          <dx-util-item data-field="cost" editor-type="dxNumberBox" :editor-options="costOptions" :label="{text: 'Purchase Cost'}">
            <dx-util-required-rule message="Purchase cost is required" />
          </dx-util-item>
          <dx-util-item data-field="salePrice" editor-type="dxNumberBox" :editor-options="costOptions">
            <dx-util-required-rule message="Sale price is required" />
          </dx-util-item>
          <dx-util-item data-field="quantity" editor-type="dxNumberBox" :editor-options="quantityOptions">
            <dx-util-required-rule message="Quantity is required" />
            <dx-util-range-rule :min="1" message="Quantity should be greater than 0" />
          </dx-util-item>
        </dx-util-group-item>
        <dx-util-item template="applySalePriceToAllTemplate" />
        <dx-util-item data-field="notes" editor-type="dxTextArea" />
        <template #vendorAdder>
          <div>
            <small class="text-warning">If you can't find the vendor on the list, add it below:</small>
            <dx-util-text-box v-model="newVendor" @enterKey="addVendor">
              <DxTextBoxButton
                :options="addVendorButton"
                name=""
                location="after"
              />
          </dx-util-text-box>
          </div>
        </template>
        <template #applySalePriceToAllTemplate>
          <div>
            <dx-util-check-box v-model="materialPurchaseForm.applySalePriceToAll" :disabled="true" text="Apply sale price to all this material item inventory" />
          </div>
        </template>
    </dx-util-form>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { Notify } from '@/@robustshell/utils'
import companyService from '@/http/requests/company/companyService'
import prepMaterialPurchaseService from '@/http/requests/prep-material/prepMaterialPurchaseService'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'

export default {
  components: {
    DxTextBoxButton,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    prepMaterialItem: {
      type: Object,
      default: () => {},
    },
    materialPurchaseId: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      popupTitle: '',
      vendors: [],
      newVendor: '',
      materialPurchaseForm: {
        title: '',
        vendorCompanyId: null,
        prepMaterialId: null,
        quantity: 0,
        cost: 0,
        salePrice: 0,
        notes: '',
        applySalePriceToAll: true,
      },
      addVendorButton: {
        icon: 'add',
        type: 'default',
        onClick: () => {
          this.addVendor()
        },
      },
    }
  },
  computed: {
    materialPurchasePopup() {
      return this.$refs.materialPurchasePopupRef.instance
    },
    itemOptions() {
      return {
        readOnly: true,
      }
    },
    vendorOptions() {
      return {
        dataSource: this.vendors,
        displayExpr: 'text',
        valueExpr: 'value',
        searcEnabled: true,
        acceptCustomValue: true,
        onCustomItemCreating: this.customItemCreating,
      }
    },
    costOptions() {
      return {
        format: '$ #,##0.##',
        min: 0,
      }
    },
    quantityOptions() {
      return {
        format: '#,##0',
        min: 1,
      }
    },

  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current !== '') {
          this.materialPurchasePopup.show()
          if (this.prepMaterialItem && this.prepMaterialItem.id > 0) {
            this.materialPurchaseForm.prepMaterialId = this.prepMaterialItem.id
            this.materialPurchaseForm.title = this.prepMaterialItem.title
          }
          if (this.materialPurchaseId && this.materialPurchaseId > 0) {
            this.getPurchaseData(this.materialPurchaseId)
          }
        }
      },
    },
  },
  mounted() {
    this.getVendors()
  },
  methods: {
    materialPurchasePopupShown() {
      this.popupTitle = 'Material Purchase Form'
    },
    materialPurchasePopupHidden() {
      this.materialPurchaseForm.id = null
      this.materialPurchaseForm.title = ''
      this.materialPurchaseForm.vendorCompanyId = null
      this.materialPurchaseForm.prepMaterialId = null
      this.materialPurchaseForm.quantity = 0
      this.materialPurchaseForm.cost = 0
      this.materialPurchaseForm.salePrice = 0
      this.materialPurchaseForm.notes = ''
      this.materialPurchaseForm.applySalePriceToAll = true
    },
    closePopup() {
      this.materialPurchasePopup.hide()
    },
    async handleSubmit() {
      const result = this.$refs.materialPurchaseFormRef.instance.validate()
      if (result.isValid) {
        if (this.materialPurchaseId) {
          this.materialPurchaseForm.id = this.materialPurchaseId
          await prepMaterialPurchaseService.update(this.materialPurchaseForm)
        } else {
          await prepMaterialPurchaseService.create(this.materialPurchaseForm)
        }
        this.$emit('emit-form-saved')
        this.closePopup()
      }
    },
    getVendors() {
      this.vendors = []
      const companyType = 'vendor'
      companyService.findByCompanyType(companyType).then(response => {
        this.vendors = response.data.map(el => ({
          text: el.name,
          value: el.id,
        }))
      })
    },
    async addVendor(e) {
      if (this.newVendor?.length === 0) {
        Notify.warning('Please enter a valid company name')
        return
      }
      const payload = {
        name: this.newVendor,
      }
      const result = await companyService.createVendorByName(payload)
      this.getVendors()
      this.materialPurchaseForm.vendorCompanyId = result.id
    },
    async getPurchaseData(materialPurchaseId) {
      const result = await prepMaterialPurchaseService.fetchById(materialPurchaseId)
      const materialPurchaseData = result.data
      this.materialPurchaseForm.prepMaterialId = materialPurchaseData.prepMaterialId
      this.materialPurchaseForm.title = materialPurchaseData.title
      this.materialPurchaseForm.cost = materialPurchaseData.cost
      this.materialPurchaseForm.salePrice = materialPurchaseData.salePrice
      this.materialPurchaseForm.quantity = materialPurchaseData.quantity
      this.materialPurchaseForm.vendorCompanyId = materialPurchaseData.vendorCompanyId
      this.materialPurchaseForm.notes = materialPurchaseData.notes
      this.materialPurchaseForm.buyerCompanyId = materialPurchaseData.buyerCompanyId
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
